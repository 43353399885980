.chat-container-cris {
  position: fixed;
  bottom: 20px;
  right: 40px;
  padding: 10px;
  border-radius: 20px;
  width: 80px;
  height: 80px;
  max-width: 1000px;
  max-height: 1200px;
  background-color: #f1f1f1;
  box-shadow: 0 0 10px rgba(177, 171, 171, 0.1);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  z-index: 55;
  justify-content: space-between;
  transition: width 0.3s, height 0.3s;
}

.chat-container-cris.closed {
  width: 0px;
  height: 0px;
}

.chat-container-cris.open {
  width: 65vw; 
  height: 85vh; 
}

/* Media queries for responsiveness */
@media only screen and (max-width: 600px) {
  .chat-container-cris.open {
    width: 80vw;
    height: 80vh;
  }
}

@media only screen and (max-width: 400px) {
  .chat-container-cris.open {
    width: 90vw;
    height: 90vh;
  }
}

.chat-button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 20px;
  right: 40px;
  z-index: 56;
}

.iframe-cris {
  width: 100%;
  height: 100%;
  border: none;
}
