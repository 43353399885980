.toc-list {
    list-style: none;
    padding: 0;
  }
  
  .toc-list li {
    margin-bottom: 8px;
  }
  
  .toc-list button {
    background: none;
    border: none;
    text-align: left;
    width: 100%;
    cursor: pointer;
  }
  
  .toc-list li.active {
    background-color: #ff8c0042;
    color: #a8abcf1c;
    color: white;
    border-radius: 6px;
    padding: 8px;
  }
  
