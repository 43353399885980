.editor-page {
    display: flex;
    flex-direction: row;
    margin-left: 10em;
  }
  
  .container {
    margin-bottom: 50px;
    margin-top: 80px;
    min-width: 40vw;
    min-height: 60vh;
    display: flex;
    flex-direction: column;
    align-items: center; 
  }
  
  .overview-content {
    padding: 10px;
    width: 400px;
    position: -webkit-sticky;
    position: sticky;
    top: 50%; 
    transform: translateY(-50%); 
    height: 70vh;
    margin-left: 20px;
  }
  
  .reactquill-editor {
    position: 'absolute';
    z-index: 5;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: '80vh';
    width: '90vh';

  }

  .editor-content {
    flex: 1;
    padding: 10px;
  }

  .content-container {
    padding: 10px;
  }
  
  .article-header {
    padding-bottom: 10px; 
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  
  .last-edited,
  .author,
  .table-of-contents {
    font-size: 0.8rem;
    margin: 5px 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  
  .table-of-contents {
    margin-top: 10px;
    padding-bottom: 5px; 
    display: flex;
    flex-direction: column;
    align-items: left;
  }
  
  .list-item {
    font-size: 0.9rem;
    margin: 5px 0;
  }
  
  .button-expand {
    font-size: 0.8rem;
    margin-left: 10px;
    color: secondaryColor;
    border-color: secondaryColor;
    border: 1px solid secondaryColor;
    padding: 5px 10px; 
  }
  
  .editor-buttons {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
  
  .save-icon,
  .cancel-icon,
  .edit-icon {
    display: flex;
    flex-direction: row; 
    align-items: center;
    justify-content: flex-end; 
    margin-left: auto; 
  }
  
  
  @media only screen and (max-width: 1100px) {
    .editor-container {
      flex-direction: column;
    }
  
    .editor-buttons {
      flex-direction: column;
      margin-top: 20px;
    }
  
    .editor-buttons button {
      margin-bottom: 10px;
    }
  
    .editor {
      width: 100%;
      margin-right: 0;
      position: static;
      top: auto;
      left: auto;
    }

    .overview-content{
        display: none;
    }

    .editor-page{
        margin-left: 0;
    }
  }
  

  .scroll-to-top {
    position: fixed;
    bottom: 20px;
    left: 5em;
    z-index: 100;
  }

  @media only screen and (max-width: 1200px) {
    .scroll-to-top {
      display: none;
    }
  }