@media only screen and (max-width: 600px) {
    .cardContainer {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  
    .card {
      text-align: center;
      width: 80%; /* Adjust the width based on your preference */
      margin: 10px;
    }
  
    .card img {
      display: none; /* Hide the image on smaller screens */
    }
  }

.container {
    max-width: 1200px; /* Adjust the max width as needed */
    margin: 0 auto; /* Center the container */
  }
  
  .logo-container,
  .profile-content,
  .card-container,
  .text-container {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  

  .tab-content {
    min-width: 100em;
    border-radius: 30px;
    min-height: 40vh;
    padding: 20px;
    background-color: #9abae23d;
  }
  
  .tab-panel {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .text-container {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .subtabs {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
